<template>
  <div>
    <el-form
      :model="formData"
      :rules="rules"
      ref="formRef"
      label-width="200px"
      class="detail-form"
      label-suffix="："
    >
      <div class="info-area">
        <div class="title">发布国家或地区</div>
        <el-form-item
          label="发布国家或地区"
          prop="releaseArea"
          :rules="[{ required: true, validator: checkArea }]"
        >
          <!-- <el-cascader
            clearable
            size="small"
            :disabled="disabled"
            :options="areaList"
            v-model="releaseArea"
            placeholder="请选择发布国家或地区"
            :props="areaProps"
          ></el-cascader> -->
          <el-select
            v-model="releaseArea"
            :disabled="disabled"
            multiple
            filterable
            placeholder="请选择发布国家或地区"
          >
            <li class="el-select-dropdown__item">
              <el-checkbox v-model="isAllChecked" @change="checkToggle"
                >全选</el-checkbox
              >
            </li>
            <el-option
              v-for="item in areaList"
              :key="item.countryId"
              :label="item.countryName"
              :value="item.countryId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="info-area">
        <div class="title">软件版本</div>
        <el-form-item label="包名" prop="apkForm.packageName">
          <span
            :class="{
              highLight: formData.apkForm && formData.apkForm.packageNameLight,
            }"
            >{{ formData.apkForm && formData.apkForm.packageName }}</span
          >
        </el-form-item>
        <el-form-item label="发布版本" prop="apkForm.versionName">
          <el-descriptions direction="vertical" :column="4" border>
            <el-descriptions-item label="文件名">
              <span :class="{ highLight: formData.apkForm.fileNameLight }">{{
                formData.apkForm && formData.apkForm.fileName
              }}</span></el-descriptions-item
            >
            <el-descriptions-item label="版本（版本号）"
              ><span
                :class="{ highLight: formData.apkForm.versionNameLight }"
                >{{ formData.apkForm && formData.apkForm.versionName }}</span
              ></el-descriptions-item
            >
            <el-descriptions-item label="上传时间">{{
              formData.apkForm && formData.apkForm.createTime
            }}</el-descriptions-item>
            <el-descriptions-item label="操作"
              ><el-button
                type="text"
                class="ml10"
                @click="handleDownloadApk()"
                v-if="formData.apkForm.filePath"
                >下载</el-button
              ></el-descriptions-item
            >
          </el-descriptions>
        </el-form-item>
        <el-form-item>
          <UploadFile
            :disabled="disabled"
            class="ml10"
            type="apk"
            :expandData="{ appInfoId: formData.id }"
            @success="(file) => handleSuccessUpload(file, 'apk')"
          >
            <template slot="text">软件包上传</template>
          </UploadFile>
        </el-form-item>
      </div>
      <div class="info-area">
        <div class="title">应用内资费</div>
        <el-form-item label="应用内资费类型" props="tariffType">
          <el-checkbox-group
            v-model="formData.tariffType"
            :disabled="disabled"
            :class="{ highLight: formData.inAppPaymentLight }"
          >
            <el-checkbox
              v-for="item in tariffTypeList"
              :key="item.key"
              :label="item.key"
              >{{ item.value }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div class="info-area">
        <div class="title">内容分级</div>
        <div class="sub-desc">
          内容分级将用于标识您的应用适宜用户的年龄段，请按应用内容如实填写
        </div>
        <el-form-item label="年龄分类标准" prop="ageLevel">
          <el-button
            size="mini"
            type="primary"
            :disabled="disabled"
            @click="ageLevelVisible = true"
            >设置</el-button
          >
          <el-button type="text" v-show="!isOpen" @click="isOpen = !isOpen"
            >展开<i class="el-icon-arrow-down"></i
          ></el-button>
          <el-button type="text" v-show="isOpen" @click="isOpen = !isOpen"
            >收起<i class="el-icon-arrow-up"></i
          ></el-button>
          <div v-show="isOpen" class="remark-text">
            内容分级用途：<br />
            • 告知消费者，您的应用适宜用户的年龄段；<br />
            • 依法在特定区域或特定用户屏蔽或过滤您的内容。<br />
            开发者责任：<br />
            •
            为您的应用或更新版本完成内容分级，当您的应用提供的内容发生变化时，应立即重新进行内容分级；<br />
            •
            开发者应当保证其提交的与年龄分级相关的信息的真实性、准确性和合法性，并承担相应责任。对内容的不如实描述可能导致应用审核不通过、下架或封停。<br />
            荣耀权限：<br />
            •
            荣耀有权对开发者的自检分级结果和相关材料进行审核，并根据审核情况对应用分级结果进行调整。<br />
            •
            如果荣耀发现或者接到用户投诉认为开发者的应用存在任何不符合法律法规规定或荣耀相关标准的内容的，荣耀有权根据判断结果采取下架、屏蔽、断开链接等措施。<br />
            •
            为实施该制度并对开发者提交的应用进行相应审核、分级和调整，不意味着荣耀对开发者的应用的合法、合规性承担任何明示或默示的担保、承诺，开发者仍需就其应用内容承担完全的担保责任。<br />
            • 荣耀有权对应用分级制度、年龄分级标准进行调整并通知开发者。<br />
          </div>
          <el-descriptions direction="vertical" :column="4" border>
            <el-descriptions-item label="分级系统"
              >年龄分级标准</el-descriptions-item
            >
            <el-descriptions-item label="适用区域">全球</el-descriptions-item>
            <el-descriptions-item label="分级类别">
              <span :class="{ highLight: formData.ratingIdLight }">{{
                formData.ageLevel | filerAge("level")
              }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="分级描述">
              <span :class="{ highLight: formData.ratingIdLight }">{{
                formData.ageLevel | filerAge("value")
              }}</span>
            </el-descriptions-item>
          </el-descriptions>
        </el-form-item>
      </div>
      <div class="info-area">
        <div class="title">隐私声明</div>
        <el-form-item label="隐私政策网址" prop="privacyPolicyUrl">
          <el-input
            size="small"
            :disabled="disabled"
            v-model="formData.privacyPolicyUrl"
            :class="{ highLight: formData.privacyPolicyUrlLight }"
            placeholder="请输入URL，以http://或者https://开头"
          ></el-input>
        </el-form-item>
        <el-form-item label="隐私权利" prop="privacyRightUrl">
          <el-input
            size="small"
            :disabled="disabled"
            v-model="formData.privacyRightUrl"
            :class="{ highLight: formData.privacyRightUrlLight }"
            placeholder="请输入URL，以http://或者https://开头"
          ></el-input>
        </el-form-item>
      </div>
      <!-- <div class="info-area">
        <div class="title">版权信息</div>
        <el-form-item label="电子版权证书" prop="ecopyrightUrl">
          <el-input
            size="small"
            :disabled="disabled"
            :class="{ highLight: formData.ecopyrightUrlLight }"
            v-model="formData.ecopyrightUrl"
            placeholder="请上传电子版权证书"
          ></el-input>
          <UploadFile
            :limSize="5"
            :disabled="disabled"
            class="ml10"
            v-model="formData.ecopyrightUrl"
          ></UploadFile>
          <div class="remark-text">
            请上传PDF格式文件，不能超过5MB。<br />
            电子版软件著作权登记证书（电子版权证书）与纸质版软件著作权登记证书具有同等法律效力，可在线验证，官方申请通道可同时颁发纸质/电子版权证书，已获得纸质证书的APP可免费补领电子版权证书。
          </div>
        </el-form-item>
        <el-form-item
          label="应用版权证书或代理证书"
          prop="info1Url"
          :rules="[
            {
              required: isIncludesCN,
              message: '请上传应用版权证书或代理证书',
              trigger: 'change',
            },
          ]"
        >
          <div class="upload-area">
            <div v-for="(name, index) in elecCopyrightList" :key="index">
              <UploadImg
                :limSize="15"
                :disabled="disabled"
                class="upload-horizontal"
                v-model="formData[name]"
                :acceptType="['jpg', 'jpeg', 'png']"
                :class="{ highLight: formData[`${name}Light`] }"
                @success="(file) => handleSuccessUpload(file, name)"
              ></UploadImg>
              <div v-if="index === 0" class="remark-text">软著</div>
              <div v-if="index === 1" class="remark-text">
                版权授权书（软著方授权发行方），如自研可不提供（选填）
              </div>
              <div v-if="index === 2" class="remark-text">
                游戏版权免责声明内容已并入服务协议，无需上传
              </div>
            </div>
          </div>
          <div class="remark-text" style="margin-top: 10px">
            请上传应用版权证书或代理证书，支持JPG、JPEG、PNG格式，单个图片最大不能超过15M
          </div>
        </el-form-item>
      </div> -->
      <!-- <div class="info-area">
        <div class="title">版号</div>
        <div class="sub-desc">
          请在游戏启动时显著位置标明备案号（文网游）、游戏著作权人、出版单位、批准文号（新厂出审）、出版物号（ISBN）、健康游戏忠告等信息、否则上架审核会被驳回。
        </div>
        <el-form-item
          label="版号信息"
          prop="versionInfo"
          :rules="[{ required: isIncludesCN, message: '请输入版号信息' }]"
        >
          <el-input
            size="small"
            show-word-limit
            :maxlength="50"
            :disabled="disabled"
            v-model="formData.versionInfo"
            :class="{ highLight: formData.versionInfoLight }"
            placeholder="样例：新广出厂【2021】xxx号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="版号证明"
          prop="requiredForm"
          :rules="[{ required: isIncludesCN, message: '请上传版号证明' }]"
        >
          <div class="upload-area">
            <div>
              <UploadImg
                :disabled="disabled"
                class="upload-horizontal"
                v-model="formData.approvalUrl"
                :acceptType="['jpg', 'jpeg', 'png']"
                :class="{ highLight: formData.approvalUrlLight }"
              ></UploadImg>
              <div class="remark-text">版号批文【官方合作渠道】</div>
            </div>
            <div>
              <UploadImg
                :disabled="disabled"
                class="upload-horizontal"
                v-model="formData.authcUrl"
                :acceptType="['jpg', 'jpeg', 'png']"
                :class="{ highLight: formData.authcUrlLight }"
              ></UploadImg>
              <div class="remark-text">版号授权书</div>
            </div>
            <div>
              <UploadImg
                :disabled="disabled"
                class="upload-horizontal"
                v-model="formData.replenishUrl"
                :acceptType="['jpg', 'jpeg', 'png']"
                :class="{ highLight: formData.replenishUrlLight }"
              ></UploadImg>
            </div>
          </div>
          <div class="remark-text" style="margin-top: 10px">
            图片格式：JPG、JPEG、PNG、PDF格式，单个图片最大不能超过4M
          </div>
          <div class="remark-text">
            请提供网络游戏出版物号（ISBN）核发单或版号批文。首发及开计费内测游戏必须提供，预约和不开计费封测的游戏可先上传版号办理进度或说明。若版号批文中的运营单位非上传开发者，则需提供版号授权书
          </div>
        </el-form-item>
        <el-form-item
          label="授权书有效期"
          prop="expires"
          v-if="formData.authcUrl"
        >
          <el-radio-group :disabled="disabled" v-model="formData.expires">
            <el-radio :label="2">指定时间</el-radio>
            <el-radio :label="1">永久有效</el-radio>
          </el-radio-group>
          <br />
          <el-date-picker
            size="small"
            type="daterange"
            v-model="formData.ersionPeriod"
            v-if="formData.expires === 2"
            range-separator="至"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
            start-placeholder="开始日期"
            :class="{
              highLight: formData.startTimeLight || formData.endTimeLight,
            }"
          ></el-date-picker>
          <div class="remark-text">
            请填写授权书有效期，如为多重授权，需填写最后一重授权书有效期
          </div>
        </el-form-item>
      </div> -->
      <div class="info-area">
        <div class="title">应用审核信息</div>
        <div class="sub-desc">
          如果您的App部分功能需要用户进行身份验证（例如，提供登录权限、查看应用内容、在线购买等）后才能使用，请提供测试帐号信息，该测试帐号会被审核员使用，以便完成登录、查看、购买等功能的审核。
        </div>
        <el-form-item label="" prop="auditLogin">
          <el-checkbox v-model="formData.auditLogin" :disabled="disabled">
            需要登录进行审核
          </el-checkbox>
        </el-form-item>
        <el-form-item
          label="测试账号"
          prop="testAccount"
          v-if="formData.auditLogin"
        >
          <el-input
            size="small"
            :disabled="disabled"
            style="width: 300px"
            autocomplete="off"
            placeholder="请输入账号"
            v-model="formData.testAccount"
            :class="{ highLight: formData.testAccountLight }"
          ></el-input>
          <el-input
            size="small"
            :disabled="disabled"
            show-password
            autocomplete="off"
            placeholder="请输入密码"
            v-model="formData.testPassword"
            style="width: 300px; margin-left: 10px"
            :class="{ highLight: formData.testPasswordLight }"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            size="small"
            :rows="6"
            type="textarea"
            :maxlength="300"
            show-word-limit
            :disabled="disabled"
            v-model="formData.remark"
            placeholder="请输入备注"
            :class="{ highLight: formData.remarkLight }"
          ></el-input>
        </el-form-item>
      </div>
      <div class="info-area" v-if="releaseType !== 3">
        <div class="title">上架</div>
        <el-form-item label="上架时间" prop="normalReleaseType">
          <el-radio-group
            :disabled="disabled"
            v-model="formData.normalReleaseType"
            :class="{ highLight: formData.countryLight }"
          >
            <el-radio :label="1">立即上线</el-radio>
            <!-- <el-radio :label="2">指定时间</el-radio> -->
          </el-radio-group>
          <br />
          <el-date-picker
            size="small"
            type="datetime"
            :disabled="disabled"
            v-model="formData.normalReleaseTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-if="formData.normalReleaseType === 2"
            placeholder="审核通过后会在您指定的时间上线"
            :class="{ highLight: formData.releaseTimeLight }"
            :picker-options="{
              disabledDate(time) {
                return time.getTime() < Date.now() - 8.64e7;
              },
              selectableRange: `${new Date().toLocaleTimeString(
                'it-IT'
              )} - 23:59:59`,
            }"
            :default-time="new Date().toLocaleTimeString('it-IT')"
          ></el-date-picker>
        </el-form-item>
      </div>
    </el-form>
    <el-dialog
      title="请根据应用提供的内容选择分级"
      :close-on-click-modal="false"
      :visible.sync="ageLevelVisible"
    >
      <el-row v-for="item in ageLevelList" :key="item.key" class="remark-text">
        <el-col :span="4">
          <el-radio v-model="ageLevel" :label="item.key">{{
            item.value
          }}</el-radio>
        </el-col>
        <el-col :span="18">
          <span>{{ item.desc }} </span>
        </el-col>
      </el-row>
      <span slot="footer">
        <el-button size="small" type="primary" @click="handleSetAgeLevel"
          >确定</el-button
        >
        <el-button size="small" @click="ageLevelVisible = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Base64 } from "js-base64";
import { mapGetters } from "vuex";
import UploadFile from "@/components/UploadFile.vue";
import UploadImg from "@/components/UploadImg.vue";
import {
  tariffTypeList,
  ageLevelList,
  CHNID,
} from "@/common/constant/constant.js";
export default {
  components: { UploadFile, UploadImg },
  props: {
    versionInfoData: {
      type: Object,
      default() {
        return {};
      },
    },
    operationType: { default: "" },
    releaseType: { default: "" },
  },
  created() {
    this.initData(this.versionInfoData);
    this.$store.dispatch("updateAreaListList");
  },
  data() {
    const checkUrl = (rule, value, callback) => {
      // 校验url
      // eslint-disable-next-line no-useless-escape
      const patt = /(http|https):\/\/([\w.]+\/?)\S*/;
      if (value && !patt.test(value)) {
        return callback(new Error("请输入正确的网址"));
      } else {
        callback();
      }
    };
    return {
      tariffTypeList,
      ageLevelList,
      isOpen: false, // 控制内容分级的展开与收起
      ageLevelVisible: false, // 控制年龄阶层的显隐藏
      ageLevel: "", //弹框中选择的年龄分级
      elecCopyrightList: [
        "info1Url",
        "info2Url",
        "info3Url",
        "info4Url",
        "info5Url",
      ], // 应用版权证书
      versionNumberCtfList: ["", "", ""], // 版号证明
      formData: {},
      rules: {
        // 用于显示必输的红色星号
        requiredForm: [
          {
            required: true,
            message: "该信息为必输信息",
          },
        ],
        ageLevel: [{ required: true, message: "请选择年龄分级标准" }],
        "apkForm.packageName": [{ required: true, message: "请上传软件包" }],
        "apkForm.versionName": [{ required: true, message: "请上传软件包" }],
        privacyPolicyUrl: [
          { required: true, message: "请输入隐私政策网址" },
          { validator: checkUrl },
        ],
        privacyRightUrl: [{ validator: checkUrl }],
        info1Url: [
          {
            required: true,
            message: "请上传应用版权证书或代理证书",
            trigger: "change",
          },
        ],
        versionInfo: [{ required: true, message: "请输入版号信息" }],
        approvalUrl: [
          { required: true, message: "请上传版号批文或版号授权书" },
        ],
        normalReleaseType: [{ required: true, message: "请选择上架时间" }],
        expires: [{ required: true, message: "请选择授权书有效期" }],
      },
      releaseArea: [],
      areaProps: {
        value: "countryId",
        // value: "id",
        label: "countryName",
        children: "honorAppCountryList",
        multiple: true,
        checkStrictly: true,
      },
      isAllChecked: false,
    };
  },
  computed: {
    ...mapGetters(["areaList"]),
    disabled() {
      return this.operationType === "view" ? true : false;
    },
    isIncludesCN() {
      // 判断是否包含中国
      return this.getReleaseAreaIds(this.releaseArea).includes(CHNID);
    },
  },
  filters: {
    filerAge(key, fieldName) {
      return (
        (ageLevelList.find((item) => item.key === key) || {})[fieldName] || ""
      );
    },
  },
  methods: {
    checkToggle() {
      if (this.releaseArea.length < this.areaList.length) {
        this.releaseArea = this.areaList.map((item) => item.countryId);
      } else {
        this.releaseArea = [];
      }
    },
    handleSetAgeLevel() {
      this.formData.ageLevel = this.ageLevel;
      this.$refs.formRef.clearValidate("ageLevel");
      this.ageLevelVisible = false;
    },
    handleDownloadApk() {
      const url = this.formData.apkForm.filePath;
      if (url) {
        window.open(url);
      } else {
        this.$message.error("无下载的软件包");
      }
    },
    handleSuccessUpload(file, name) {
      if (name === "apk") {
        const {
          pakageName,
          version,
          versionName,
          fileProperty,
          sign1,
          sign256,
          signSha1,
          signMd5,
          permissions,
        } = file;
        const createTime =
          new Date().toLocaleDateString().split("/").join("-") +
          " " +
          new Date().toLocaleTimeString("it-IT");
        const apkForm = {
          ...fileProperty,
          filePath: fileProperty.accessPath,
          fileUuid: fileProperty.fileId,
          packageName: pakageName,
          versionCode: version,
          versionName,
          fileMd5: signMd5,
          fileSha256: sign1,
          sha256: sign256,
          signSha1,
          permissions,
          createTime,
        };
        this.formData.apkId = undefined; // 重新上传包后，不需要给后端传apkId
        this.$set(this.formData, "apkForm", apkForm);
        this.$refs.formRef.clearValidate([
          "apkForm.packageName",
          "apkForm.versionName",
        ]);
      } else {
        this.$refs.formRef.clearValidate(name);
      }
    },
    // 其他
    initData(data) {
      //初始改数组数据，否则若父组件为传入，会报错
      const formData = {
        requiredForm: 1, // 用于显示必输的红色星号，无业务上的意义
        tariffType: [], // 应用内资费类型
        releaseArea: [],
        expires: 1,
        normalReleaseType: 1,
        testAccount: "",
        testPassword: "",
        ...data,
      };
      formData.auditLogin = formData.testAccount ? true : false;
      formData.testAccount = Base64.decode(formData.testAccount);
      formData.testPassword = Base64.decode(formData.testPassword);
      this.formData = formData;
      this.ageLevel = data.ageLevel; // 年龄分层
      this.getArrReleaseArea(formData.releaseArea); // 发布国家或地区
    },
    checkArea(rule, value, callback) {
      // 校验发布国家或地区
      if (this.releaseArea.length === 0) {
        return callback(new Error("请选择发布国家或地区"));
      } else {
        callback();
      }
    },
    // 根据id获取完整的id数组（包含上下级关系）
    async getArrReleaseArea(idList = []) {
      const areaList = this.areaList;
      const arr = [];
      if (!(idList.length === 0 || areaList.length === 0)) {
        idList.map((key, index) => {
          const _findIndex = areaList.findIndex(
            (item) => item[this.areaProps.value] == key
          );
          if (_findIndex == -1) {
            const parentkey = this.getParentKey(key, areaList);
            if (parentkey) arr[index] = [parentkey, parseInt(key)];
          } else {
            arr[index] = parseInt(key);
          }
        });
      }
      this.releaseArea = arr;
    },
    // 根据arr（包含上下级关系）获取id数组
    getReleaseAreaIds(arrList) {
      const newArr = arrList.filter((item) => item != "");
      const idList = newArr.map((arr) =>
        typeof arr == "object" ? (arr[1] ? arr[1] : arr[0]) : arr
      );
      return idList;
    },
    // 根据传入的孩子节点的key获取父节点key
    getParentKey(childrenKey, list = []) {
      let parentItem = "";
      for (let item of list) {
        const childrenList = item[this.areaProps.children];
        if (childrenList && childrenList.length !== 0) {
          const obj = childrenList.find(
            (item1) => item1[this.areaProps.value] == childrenKey
          );
          if (obj) {
            item.disabled = false;
            parentItem = item;
            break;
          }
        }
      }
      return parentItem[this.areaProps.value];
    },
    // 暴露给父组件获取表单数据
    getFormData() {
      const data = this.formData;
      const releaseArea = this.getReleaseAreaIds(this.releaseArea); // 将所有已选叶子节点的国家或地区返回
      data.releaseCountry = releaseArea; // 发布国家
      data.compatiableDevice = 1; // 兼容设备：1手机
      data.inAppPayment = data.tariffType.join("|");
      data.ratingId = data.ageLevel;
      if (data.normalReleaseType == 1) {
        data.normalReleaseTime = "";
      }
      //版号信息&应用审核信息
      const extendInfo = {
        approvalUrl: data.approvalUrl || "", //版号批文文件url
        authcUrl: data.authcUrl || "", //版号授权书文件url
        replenishUrl: data.replenishUrl || "", //版号补充文件url
        expires: data.expires, //授权书有效期：1永久，2指定时间
        startTime: data.ersionPeriod && data.ersionPeriod[0], //	授权书有效期：起始时间 格式：yyyy-MM-dd
        endTime: data.ersionPeriod && data.ersionPeriod[1], //	授权书有效期：截止时间 格式：yyyy-MM-dd
        testAccount: Base64.encode(data.testAccount) || "", //	测试账号
        testPassword: Base64.encode(data.testPassword) || "", //	测试账号密码
        remark: data.remark || "", //	备注
        versionInfo: data.versionInfo || "", //	版号信息
      };
      data.extendInfo = extendInfo;

      return data;
    },
    // 暴露给父组校验表单数据
    validate() {
      let data = false;
      let msg = "";
      this.$refs.formRef.validate((valid) => {
        data = valid;
      });
      if (data) {
        if (this.isIncludesCN) {
          const { approvalUrl, authcUrl } = this.formData;
          // if (!approvalUrl && !authcUrl) {
          //   data = false;
          //   msg = "请上传版号批文或版号授权书；";
          // }
        }
      }
      return { valid: data, msg };
    },
  },
  watch: {
    versionInfoData(data) {
      this.initData(data);
    },
    releaseArea(list) {
      // 更新store中的releaseArea，供其他组件使用（如内测）
      this.$store.commit("updateState", ["releaseArea", list]);
    },
  },
};
</script>
<style scoped lang="scss">
$width: 240px;
$height: 135px;
.detail-form {
  .el-input,
  .el-textarea {
    width: 500px;
  }
}
.info-area {
  .title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
    padding-top: 30px;
    border-top: 1px solid #dfdfdf;
  }
  .sub-desc {
    font-size: 14px;
    line-height: 1.5;
    color: #858b90;
    width: 90%;
    margin-bottom: 20px;
  }
}
.upload-area {
  display: flex;
  flex-wrap: wrap;
  & > div {
    display: block;
    width: $width;
    margin-right: 10px;
  }
}
.upload-horizontal {
  width: $width;
  height: $height;
  margin-right: 10px;
  padding: 5px;
  box-sizing: border-box;
}
</style>
